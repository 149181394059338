/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* MuseoSansRounded font family */
@font-face {  font-family: "MuseoSansRounded"; font-weight: 100; src: url("/assets/fonts/MuseoSansRounded-100.otf");  }
@font-face {  font-family: "MuseoSansRounded"; font-weight: 300; src: url("/assets/fonts/MuseoSansRounded-300.otf");  }
@font-face {  font-family: "MuseoSansRounded"; font-weight: 500; src: url("/assets/fonts/MuseoSansRounded-500.otf");  }
@font-face {  font-family: "MuseoSansRounded"; font-weight: 700; src: url("/assets/fonts/MuseoSansRounded-700.otf");  }
@font-face {  font-family: "MuseoSansRounded"; font-weight: 900; src: url("/assets/fonts/MuseoSansRounded-900.otf");  }
@font-face {  font-family: "MuseoSansRounded"; font-weight: 1000; src: url("/assets/fonts/MuseoSansRounded-1000.otf");  }

@import 'theme/moh-font.scss';
@import 'theme/scrollbar.scss';
@import "~@ng-select/ng-select/themes/material.theme.css";

h1 { @extend .font-28; color: var(--ion-color-secondary); font-weight: 700; }
h2 { @extend .font-22; color: var(--ion-color-secondary); }
h3 { @extend .font-20; color: var(--ion-color-secondary); }
h4 { @extend .font-18; color: var(--ion-color-secondary); font-weight: 700; }
h5 {
  @extend .font-16;
  margin: 32px 15px 16px 15px;
  font-weight: 800;
  line-height: 20px;
  color: var(--ion-color-secondary);
}
h6 {
  @extend .font-14;
  padding-top: 10px;
  font-weight: 700;
  line-height: 20px;
  color: var(--ion-color-secondary);
}
p { @extend .font-14; }

.leaflet-bar {
  a {
    color: var(--dodger-blue) !important;
  }
  a:hover {
    color: black !important;
  }
}

.no-content-block {
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 14px;
  text-align: center;
  p {
    font-weight: 400;
    @extend .font-14;
    line-height: 1.4em;
    color: var(--ion-color-secondary);
    margin-bottom: 1.6em;
  }
}

.warning {
  font-size: 12px;
  ion-icon {
    font-size: 17px;
    vertical-align: top;
  }
}

.vertical-center {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  &.spinner {
    position: absolute;
    z-index: 1000;
  }
}

.sticky-box {
  position: sticky;
  top: 0;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: initial;
  &.line-1 {
    -webkit-line-clamp: 1;
  }
  &.line-2 {
    -webkit-line-clamp: 2;
  }
  &.line-3 {
    -webkit-line-clamp: 3;
  }
  &.line-4 {
    -webkit-line-clamp: 4;
  }
}
.text-overflow-line-clamp-2 {
  @extend .text-overflow-ellipsis;
  -webkit-line-clamp: 2;
}
.text-overflow-line-clamp-3 {
  @extend .text-overflow-ellipsis;
  -webkit-line-clamp: 3;
}

.no-image {
  background: var(--ghost-white) url('assets/images/no-image.png');
  background-size: 80px;
  width: 100%;
  aspect-ratio: 4/3;
  background-repeat: no-repeat;
  background-position: center;
}

ion-button {
  font-style: normal;
  font-weight: 600;
  @extend .font-16;
  &.button-small {
    @extend .font-14;
    height: 32px;
  }
  line-height: 19px;
  height: 40px;
  text-align: center;
  text-transform: none;
  --border-radius: 4px;
  &.btn {
    --margin-bottom: 2em;
    background: var(--ion-color-primary-contrast);
    box-shadow: 0 2px 10px rgba(8, 56, 79, 0.1);
    border-radius: 4px;
  }
}

.ion-page {
  background: var(--alice-blue);

  ion-header {
    ion-toolbar {
      ion-title {
        text-align: center;
        @extend .font-16;
        font-weight: 600;
        padding-top: 0.7em;
      }
      ion-buttons {
        --margin-top: 0;
        margin-top: 5px;
        margin-inline-end: 0;
      }
    }
  }

  ion-toolbar {
    ion-button,
    ion-back-button,
    ion-menu-button,
    .sc-ion-back-button-ios-h {
      --color: var(--ion-color-primary-contrast);
    }
    .back-button {
      &::part(native){
        font-size: 23px;
        //margin-top: 5px;
        @extend .icon-button-32;
      }
    }
  }

  ion-tabs {
    ion-tab-bar {
      height: 55px;
      padding-bottom: 0px;
      padding-top: 0px;
      ion-tab-button[aria-selected=true] {
        box-shadow: 0px -3px 0px 0px var(--ion-color-primary) inset;
        color: var(--ion-color-primary);
      }
      ion-tab-button {
        &.tab-selected {
          ion-label {
            color: var(--ion-color-primary);
          }
        }
      }
      ion-label {
        @extend .font-14;
        font-weight: 600;
        line-height: 1em;
        white-space: nowrap;
        overflow: visible;
      }
      &:not(.without-label-underline) {
        ion-tab-button {
          &.tab-selected {
            ion-label {
              color: var(--ion-color-primary);
              display: flex;
              align-items: center;
              flex-grow: 1;
              position: relative;
              margin: unset;
              &::after {
                content: "";
                height: 4px;
                background-color: var(--ion-color-primary);
                position: absolute;
                bottom: 0px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  ion-segment {
    border-bottom: 0.55px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2))));
    ion-segment-button {
      --indicator-height: 0px;
      text-transform: initial;
      ion-label {
        @extend .font-14;
        font-weight: 600;
        line-height: 1em;
        white-space: nowrap;
      }
      &.segment-button-checked {
        ion-label {
          color: var(--ion-color-primary);
          display: flex;
          align-items: center;
          flex-grow: 1;
          position: relative;
          margin: unset;
          &::after {
            content: "";
            height: 4px;
            background-color: var(--ion-color-primary);
            position: absolute;
            bottom: 0px;
            width: 100%;
          }
        }
      }
    }
  }

  ion-content {
    &.moh-bg-light {
      --background: var(--alice-blue);
    }
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 0px;
    --padding-end: 0px;
  }

  ion-grid {
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    ion-col {
      padding-top: 0;
      padding-bottom: 0;
      padding-inline-end: 0;
      padding-inline-start: 0;

    }
  }

  ion-card {
    --background: var(--ion-color-primary-contrast);
    box-shadow: 0 8px 24px rgba(8, 56, 79, 0.05);
    border-radius: 8px;
    margin: 10px;
    ion-card-header {
      padding: 14px 16px 16px 16px;
      .item-native {
        --background-focused: #fff;
      }
      ion-avatar,
      ion-label {
        margin-top: 0;
        margin-bottom: 12px;
        align-self: self-start;
      }
    }
    ion-card-content {
      padding: 16px;
      &.no-padding-top {
        padding-top: 0;
      }
      div.inside-cards {
        margin: 16px 0 0 0;
        ion-card {
          --background: var(--alice-blue) !important;
          border-radius: 4px;
          box-shadow: none;
          margin: 8px 0 0 0;
          ion-card-header,
          ion-card-content {
            padding-left: 0;
            padding-right: 0;
          }
          ion-item {
            --background: transparent;
          }
          ion-list {
            background: transparent !important;
            ion-item {
              --background: transparent;
            }
          }
        }
      }
    }
    ion-card-title {
      font-style: normal;
      font-weight: 800;
      @extend .font-18;
      color: var(--ion-color-secondary);
      line-height: 20px;
    }
    ion-card-subtitle {
      font-style: normal;
      font-weight: 600;
      @extend .font-12;
      line-height: 20px;
    }
    ion-list {
      ion-item {
        height: auto;
        --min-height: 26px;
        line-height: 1em;
        font-weight: 400;
        @extend .font-14;
        color: var(--ion-color-secondary);
        span {
          place-self: normal;
          line-height: 1.4em;
          margin-inline-end: 10px;
        }
        ion-label {
          place-self: normal;
          line-height: 1.4em;
          margin: 0;
          font-weight: 400;
          strong {
            place-self: normal;
            line-height: 1.4em;
          }
        }
      }
    }
    ion-item {
      --background: var(--ion-color-primary-contrast);
      --padding-start: 0;
      //--border-color: var(--ion-border-color, var(--ion-color-step-950, rgba(255, 1, 1, 0.5)));
      --border-color: var(--gray-2);
    }
    ion-button.btn {
      --margin-bottom: 0;
      background: var(--ion-color-primary-contrast);
      --border-width: 1px;
      box-shadow: 0 2px 10px rgba(8, 56, 79, 0.1);
      border-radius: 4px;

      font-weight: 600;
      @extend .font-14;
      line-height: 17px;

      --padding-top: 6px;
      --padding-bottom: 6px;
      --padding-start: 16px;
      --padding-end: 16px;
      min-height: 18px;
      height: auto;
    }
  }

  ion-card.no-bg {
    background: transparent;
    box-shadow: none;

    ion-card-title {
      padding: 0 9%;
      font-weight: 700;
      @extend .font-16;
      line-height: 24px;
      color: var(--ion-color-secondary);
    }

    img.moh-warning {
      margin: 16px;
      width: 55px;
      text-align: -webkit-center;
    }
  }

  ion-label {
    color: var(--ion-color-secondary);
    font-weight: 800;
    //margin: 24px 0 0 0 !important;
  }

  ion-item  {
    --highlight-height: 1px;
    --highlight-color-focused: var(--ion-color-primary);
    --highlight-color-valid: var(--ion-color-primary);

    ion-label {
      h3 {
        font-weight: 700;
        color:  var(--ion-color-secondary);
      }
      p {
        font-weight: 600;
      }
      .allapot {
        padding: 0;
        margin-bottom: 0;
        line-height: 20px;
        font-size: 0.85em;
        &.active {
          color: var(--ion-color-success);
        }
        &.inactive {
          color: var(--ion-color-danger);
        }
        &.notallowed {
          color: var(--ion-color-warning-shade);
        }
      }
      .allapot::before {
        content: "• ";
        padding-right: 3px;
        font-size: 17px;
        text-indent: 0 !important;
      }
    }
  }

  ion-avatar {
    &[class*="icon-moh-primary"] {
      background: rgba(var(--ion-color-primary-rgb), 0.2);
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
    &[class*="icon-moh-teruleti-jegy"] {
      background: rgba(var(--ion-color-tertiary-rgb), 0.2);
      ion-icon {
        color: var(--ion-color-tertiary);
      }
    }
    &[class*="icon-moh-allami-jegy"] {
      background: rgba(var(--orange-peel-rgb), 0.2);
      ion-icon {
        color: var(--orange-peel);
      }
    }
    &[class*="icon-moh-nyilatkozatok"] {
      background: rgba(var(--orange-peel-rgb), 0.2);
      ion-icon {
        color: var(--orange-peel);
      }
    }
    &[class*="icon-moh-fogas"] {
      background: rgba(var(--gray-3-rgb), 0.2);
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
    ion-icon {
      width: 25px;
      height: 25px;
      position: relative;
      top: 20%;
      left: 20%;
    }
    span {
      color: var(--ion-color-secondary-contrast);
      font-weight: 800;
      font-size: 1.1em;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ion-toast {
    background: rgba(var(--ion-color-secondary-rgb), 0.2);
  }

  ion-spinner {
    display: block;
    margin: auto;
  }

  ion-datetime.moh-calendar {
    box-shadow: none;
    margin: auto;
    --ion-color-base: var(--ion-color-secondary, var(--prussian-blue)) !important;
  }
}

input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  height: 1.5em;
  --padding-top: 0;
  --margin-bottom: 0;
  border:none !important;
  color: var(--prussian-blue);
  -webkit-text-fill-color: var(--prussian-blue) !important;
  -webkit-box-shadow: 0 0 0 1000px #f7f7e9 inset;
  transition: background-color 5000s ease-in-out 0s;
}

// ion-modal style
.horgaszat-megkezdese-modal,
.horgaszatok-adott-napon-modal,
.nap-rogzites-modal,
.fogas-rogzites-modal {
  &::part(backdrop) {
    opacity: 0.1 !important;
  }
  &::part(content) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  --height: auto !important;
  align-items: flex-end;
  .ion-page {
    max-height: calc(100vh - (var(--ion-grid-padding) * 2));
    position: relative;
    contain: content;
  }

  ion-card .mat-form-field {
    width: 100%;
    &.mat-form-field-disabled .mat-form-field-underline {
      background-image: none !important;
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}

// ion-toast style
ion-toast {
  --border-radius: 6px;
  &.toast-success {
    --box-shadow: 1px 2px 1px #52914C;
  }
  &.toast-error {
    --box-shadow: 1px 2px 1px #AE2C3C;
  }
  &::part(message) {
    font-weight: 700;
    line-height: 1.1em;
  }
}

.moh-alert {
  .alert-wrapper {
    box-shadow: 0 4px 32px rgb(0 0 0 / 16%);
  }
  .alert-button-group {
    padding: 8px 8px 16px 8px;
    button.alert-button.alert-button-confirm {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      border-radius: 4px;
      text-transform: capitalize;
    }
    button.alert-button.alert-button-cancel {
      text-transform: capitalize;
    }
  }
  .alert-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--ion-color-secondary);
  }
}


// ion-spinner (ion-refresher style)
ion-loading.moh-loading {
  align-items: flex-start;
  top: 117px;
  --background: var(--ion-color-primary-contrast);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0);
  --spinner-color: var(--ion-color-primary);
  color: var(--ion-color-primary);
  .loading-wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 24px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 25%);
  }
}
// térkép popup
.poi-popup-container {
  position: absolute;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  .poi-content {
    overflow: auto;
    padding-right: 14px;
    width: 100%;
  }
  &.position-buttom {
    position: unset;
    width: calc(100% - 32px);
    max-height: unset;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
  p {
    margin: 0 auto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: var(--ion-color-secondary);
    strong {
      font-weight: 600;
    }
  }
}
.icon-button {
  &-32 {
    --padding-start: 0px;
    --padding-end: 0px;
    width: 32px;
  }
}

ion-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
