:root {
  // UI kit colors
  --prussian-blue: #08384F;
  --prussian-blue-rgb: 8, 56, 78;
  --police-blue: #395568;
  --dodger-blue: #0894FF;
  --dodger-blue-rgb: 8, 148, 255;
  --deep-blue: #3076C6;
  --button-normal: #3E95F7;
  --button-hover: #0785E6;
  --button-pressed: #1C4A7C;
  --button-disable: #C8C9CA;
  --text-disable: #E2E2E8;
  --secondery-button-hover: #DE8E34;
  --orange-peel: #F19908;
  --orange-peel-rgb: 241, 153, 8;
  --majorelle-blue: #5558DB;
  --tawny: #D45500;
  --purple-mountain-majesty: #A06BB5;
  --purple-mountain-majesty-rgb: 160, 107, 181;
  --cool-grey: #8B8BA1;
  --alice-blue: #E6F4FF;
  --verylight-blue: #E2E2E8;
  --ghost-white: #F8F8FF;
  --white: #FFFFFF;
  --white-rgb: 255, 255, 255;

  --gray-1: #F3F4F6;
  --gray-2: #E6E8ED;
  --gray-3: #CFD1D5;
  --gray-3-rgb: 207,209,213;
  --gray-4: #B8BABE;
  --gray-5: #96979A;
  --gray-6: #3a3a3c;

  --success: #30BA45;
  --success-rgb: 48, 186, 69;
  --info: #42A0F7;
  --info-rgb: 66, 160, 247;
  --warning: #FFB71C;
  --warning-rgb: 255, 183, 28;
  --danger: #FF3952;
  --danger-rgb: 255, 57, 82;
}

// -------------palette-----------------
$horgaszturizmus-dodger-blue-palette: (
  50 : #e1f2ff,
  100 : #b5dfff,
  200 : #84caff,
  300 : #52b4ff,
  400 : #2da4ff,
  500 : #0894ff,
  600 : #078cff,
  700 : #0681ff,
  800 : #0477ff,
  900 : #0265ff,
  A100 : #ffffff,
  A200 : #f2f7ff,
  A400 : #bfd5ff,
  A700 : #a6c3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$horgaszturizmus-orange-peel-palette: (
  50 : #fdf3e1,
  100 : #fbe0b5,
  200 : #f8cc84,
  300 : #f5b852,
  400 : #f3a82d,
  500 : #f19908,
  600 : #ef9107,
  700 : #ed8606,
  800 : #eb7c04,
  900 : #e76b02,
  A100 : #ffffff,
  A200 : #ffe9da,
  A400 : #ffcba7,
  A700 : #ffbb8e,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$horgaszturizmus-danger-color-palette: (
  50 : #ffe7ea,
  100 : #ffc4cb,
  200 : #ff9ca9,
  300 : #ff7486,
  400 : #ff576c,
  500 : #ff3952,
  600 : #ff334b,
  700 : #ff2c41,
  800 : #ff2438,
  900 : #ff1728,
  A100 : #ffffff,
  A200 : #fffcfd,
  A400 : #ffc9cd,
  A700 : #ffb0b5,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$horgaszturizmus-txt-palette: (
  50 : #e1e7ea,
  100 : #b5c3ca,
  200 : #849ca7,
  300 : #527484,
  400 : #2d5669,
  500 : #08384f,
  600 : #073248,
  700 : #062b3f,
  800 : #042436,
  900 : #021726,
  A100 : #61b0ff,
  A200 : #2e96ff,
  A400 : #007dfa,
  A700 : #0070e0,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);
