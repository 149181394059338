@import '/theme/moh-colors.scss';

.scrollbar-strait {
  /* Works on Firefox */
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--prussian-blue);   /* scroll thumb and track */

  /* Works on Chrome, Edge, and Safari */
    /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    //border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--prussian-blue);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--police-blue);
  }

  &.grey-scrollbar {
    ::-webkit-scrollbar-thumb {
      background: var(--verylight-blue);
    }
  }
}
