.font-28 { font-size: 28px; }
.font-24 { font-size: 24px; }
.font-22 { font-size: 22px; }
.font-20 { font-size: 20px; }
.font-18 { font-size: 18px; }
.font-16 { font-size: 16px; }
.font-14 { font-size: 14px; }
.font-12 { font-size: 12px; }
.font-9 { font-size: 9px; }
.bold { font-weight: 700; }
.black { color: black; }
