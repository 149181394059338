// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import 'moh-colors.scss';

// Background palette for light themes.
$mohosz-light-theme-background: (
  status-bar: map-get(mat.$grey-palette, 300),
  app-bar:    var(--prussian-blue),
  background: map-get(mat.$grey-palette, 50),
  hover:      rgba(black, 0.04),
  card:       white,
  dialog:     white,
  disabled-button: var(--button-disable),
  raised-button: white,
  focused-button: rgba(black, 0.12),
  selected-button: map-get(mat.$grey-palette, 300),
  selected-disabled-button: map-get(mat.$grey-palette, 400),
  disabled-button-toggle: map-get(mat.$grey-palette, 200),
  unselected-chip: map-get(mat.$grey-palette, 300),
  disabled-list-option: map-get(mat.$grey-palette, 200),
  tooltip: map-get(mat.$grey-palette, 700),
);

// Foreground palette for light themes.
$mohosz-light-theme-foreground: (
  base:              black,
  divider:           rgba(black, 0.12),
  dividers:          rgba(black, 0.12),
  disabled:          rgba(black, 0.38),
  disabled-button:   var(--text-disable),
  disabled-text:     var(--text-disable),
  elevation:         black,
  hint-text:         rgba(black, 0.38),
  secondary-text:    rgba(black, 0.54),
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$mohosz-app-primary: mat.define-palette($horgaszturizmus-dodger-blue-palette);
$mohosz-app-accent: mat.define-palette($horgaszturizmus-orange-peel-palette);

// The warn palette is optional (defaults to red).
$mohosz-app-warn: mat.define-palette($horgaszturizmus-danger-color-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mohosz-app-theme: mat.define-light-theme((
  color: (
    primary: $mohosz-app-primary,
    accent: $mohosz-app-accent,
    warn: $mohosz-app-warn,
    is-dark: false,
    foreground: $mohosz-light-theme-foreground,
    background: $mohosz-light-theme-background
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mohosz-app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


/** Ionic CSS Variables **/
:root {
  --ion-backdrop-color: var(--prussian-blue);

  /** primary **/
  --ion-color-primary: var(--dodger-blue);
  --ion-color-primary-rgb: var(--dodger-blue-rgb);
  --ion-color-primary-contrast: var(--white);
  --ion-color-primary-contrast-rgb: var(--white-rgb);
  --ion-color-primary-shade: var(--button-hover);
  --ion-color-primary-tint: #3076C6;

  /** secondary **/
  --ion-color-secondary: var(--prussian-blue);
  --ion-color-secondary-rgb: var(--prussian-blue-rgb);
  --ion-color-secondary-contrast: var(--white);
  --ion-color-secondary-contrast-rgb: var(--white-rgb);
  --ion-color-secondary-shade: var(--police-blue);

  /** tertiary **/
  --ion-color-tertiary: var(--purple-mountain-majesty);
  --ion-color-tertiary-rgb: var(--purple-mountain-majesty-rgb);
  --ion-color-tertiary-contrast: var(--white);
  --ion-color-tertiary-contrast-rgb: var(--white-rgb);
  --ion-color-tertiary-shade: #865997;
  --ion-color-tertiary-tint: #a477b5;

  /** success **/
  --ion-color-success: var(--success);
  --ion-color-success-rgb: var(--success-rgb);
  --ion-color-success-contrast: var(--white);
  --ion-color-success-contrast-rgb: var(--white-rgb);
  --ion-color-success-shade: #29a23c;
  --ion-color-success-tint: #34a045;

  /** warning **/
  --ion-color-warning: var(--warning);
  --ion-color-warning-rgb: var(--warning-rgb);
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: rgb(0, 0, 0);
  --ion-color-warning-shade: #dfa019;
  --ion-color-warning-tint: #e5aa2c;

  /** danger **/
  --ion-color-danger: var(--danger);
  --ion-color-danger-rgb: var(--danger-rgb);
  --ion-color-danger-contrast: var(--white);
  --ion-color-danger-contrast-rgb: var(--white-rgb);
  --ion-color-danger-shade: #e13248;
  --ion-color-danger-tint: #d84154;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: var(--white);
  --ion-color-dark-contrast-rgb: var(--white-rgb);
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: var(--cool-grey);
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: var(--white);
  --ion-color-medium-contrast-rgb: var(--white-rgb);
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-text-color: #000;
  --ion-item-border-color: var(--gray-4);

  --ion-padding: 8px;
  --ion-padding-start: var(--ion-padding, 8px);
  --ion-padding-end: var(--ion-padding, 8px);

  --ion-margin-top: 16px;
}

/** font-family **/
:root[mode=ios],
:root[mode=md] {
  --ion-font-family: 'MuseoSansRounded' !important;
  font-family: 'MuseoSansRounded', ui-rounded !important;
}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #2893fd;
//    --ion-color-primary-rgb: 40, 147, 253;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #3a7be0;
//    --ion-color-primary-tint: #5598ff;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80,200,255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106,100,255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47,223,117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244,245,248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0,0,0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34,36,40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


$breakpoint-xxsmall: 350px;
$breakpoint-small: 599px;
